import { capacitesMethodologiquesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const CapacitesMethodologiques = ({ goNext }) => (
  <Capacites
    goNext={goNext}
    name="capacitesMethodologiquesStage"
    label="Quelles compétences transversales penses-tu maîtriser grâce à ce stage ?"
    subLabel="Capacités méthodologiques"
    options={capacitesMethodologiquesOptions}
  />
);
