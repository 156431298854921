import { TextAreaActivite } from './TextareaActivite';

export const MesExperiences = (props) => (
  <TextAreaActivite
    fieldName="derniereExperienceStage"
    label="Raconte ta dernière expérience de stage"
    isRequired={true}
    {...props}
  />
);
