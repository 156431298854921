import { Text } from '@chakra-ui/react';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import { capacitesSocialesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const CapacitesSociales = ({ goNext }) => {
  return (
    <>
      <SummaryFrame label="Définition">
        <Text>
          <b>Compétences transversales :</b> Capacités acquises durant toutes
          les expériences vécues (y compris en dehors de l’école et / ou du
          travail). Elles sont aussi appelées compétences transversales car
          elles peuvent être utilisées dans des contextes très différents. Par
          exemple : l’autonomie et le travail en équipe. Elles sont utiles et
          mobilisées aussi bien pour la poursuite d’études que dans l’activitié
          professionnelle.
        </Text>
        <Text>
          Les compétences transversales se distinguent des compétences
          techniques, c’est-à-dire des connaissances et savoir-faire spécifiques
          à un métier ou à un secteur d’activité. Par exemple : la maîtrise
          d’une langue étrangère ou d’un logiciel informatique.
        </Text>
      </SummaryFrame>

      <Capacites
        goNext={goNext}
        name="capacitesSocialesStage"
        label="Quelles compétences transversales penses-tu maîtriser grâce à ce stage ?"
        subLabel="Capacités sociales"
        options={capacitesSocialesOptions}
      />
    </>
  );
};
