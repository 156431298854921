export var capacitesSocialesOptions = {
    communicationEcrite: {
        label: "Communication \xe9crite",
        description: "J’ai \xe9cris des textes, des mails, etc."
    },
    communicationOrale: {
        label: "Communication orale",
        description: "J’ai pris la parole \xe0 l’oral, j’ai fait des appels t\xe9l\xe9phoniques, etc."
    },
    communicationVisuelle: {
        label: "Communication visuelle",
        description: "J’ai fait des sch\xe9mas, j’ai r\xe9alis\xe9 des affiches, etc."
    },
    communicationNonVerbale: {
        label: "Communication non verbale",
        description: "J’ai adopt\xe9 des comportements ou codes vestimentaires sp\xe9cifiques, etc."
    },
    communicationNumerique: {
        label: "Communication num\xe9rique",
        description: "J’ai communiqu\xe9 sur les r\xe9seaux sociaux, etc."
    },
    ecouteActive: {
        label: "\xc9coute active",
        description: "J’ai \xe9cout\xe9 sans interrompre, j’ai compris et j’ai pos\xe9 des questions, etc."
    },
    travailEnEquipe: {
        label: "Travail en \xe9quipe",
        description: "J’ai travaill\xe9 avec le reste de l’\xe9quipe, etc."
    },
    perseverance: {
        label: "Pers\xe9v\xe9rance",
        description: "J’ai d\xfb recommencer plusieurs fois une m\xeame t\xe2che sans abandonner, etc."
    },
    diplomatie: {
        label: "Diplomatie",
        description: "J’ai trouv\xe9 des solutions en prenant en compte les avis des autres."
    },
    partageEtReceptionAvis: {
        label: "Partage et r\xe9ception d’avis",
        description: "J’ai donn\xe9 mon avis et \xe9cout\xe9 les retours et avis sur mes productions."
    }
};
export var capacitesPersonnellesOptions = {
    confianceEnSoi: {
        label: "Confiance en soi",
        description: "J’ai donn\xe9 mon avis, j’ai fait une pr\xe9sentation devant mon \xe9quipe, j’ai identifi\xe9 mes points forts, etc."
    },
    responsabilite: {
        label: "Responsabilit\xe9",
        description: "J’\xe9tais responsable d’une ou de plusieurs missions, etc."
    },
    autonomie: {
        label: "Autonomie",
        description: "J’ai travaill\xe9 seul, je devais m’organiser seul pour effectuer mes t\xe2ches, etc."
    },
    motivationEtImplication: {
        label: "Motivation et implication",
        description: "Je me suis investi malgr\xe9 des difficult\xe9s, j’ai argument\xe9 ma motivation pour le stage, etc."
    },
    gestionDuStress: {
        label: "Gestion du stress",
        description: "J’ai \xe9t\xe9 confront\xe9 \xe0 des situations stressantes, etc."
    },
    reactivite: {
        label: "R\xe9activit\xe9",
        description: "J’ai d\xfb travailler rapidement, etc."
    },
    ethique: {
        label: "\xc9thique",
        description: "J’ai respect\xe9 les valeurs de mon entreprise / de la structure dans laquelle je travaillais."
    }
};
export var capacitesMethodologiquesOptions = {
    rigueurEtFiabilite: {
        label: "Rigueur et fiabilit\xe9",
        description: "J’ai r\xe9alis\xe9 mes t\xe2ches correctement, on pouvait compter sur moi, etc."
    },
    organisation: {
        label: "Organisation",
        description: "J’ai utilis\xe9 un calendrier, un emploi du temps, une liste de t\xe2ches, etc."
    },
    strategie: {
        label: "Strat\xe9gie",
        description: "J’ai pris des d\xe9cisions en ayant des objectifs, j’ai mis en place un plan d’action, etc."
    },
    priseDeDecisions: {
        label: "Prise de d\xe9cisions",
        description: "J’ai fait des choix assum\xe9s, etc."
    },
    gestionDuTemps: {
        label: "Gestion du temps",
        description: "J’avais des dates butoires \xe0 respecter, j’avais un calendrier, etc."
    },
    curiosite: {
        label: "Curiosit\xe9",
        description: "J’ai pos\xe9 des questions, j’ai cherch\xe9 des informations, etc."
    },
    imagination: {
        label: "Imagination",
        description: "J’ai donn\xe9 de nouvelles id\xe9es, j’ai tent\xe9 de cr\xe9er des choses diff\xe9rentes, etc."
    },
    capaciteAnalyse: {
        label: "Capacit\xe9 d’analyse",
        description: "J’ai observ\xe9 une situation, compris et restitu\xe9 ses \xe9l\xe9ments importants pour prendre une d\xe9cision adapt\xe9e."
    },
    ouvertureSocioculturelle: {
        label: "Ouverture socioculturelle",
        description: "Je me suis int\xe9ress\xe9 \xe0 des choses que je ne connaissais pas."
    }
};
