import { Box, Text } from '@chakra-ui/react';
import {
  initFormikValuesFromSchema,
  validateSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { getQuestionnementLabelByKey } from '@inspire/data/static/questionnements';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';
import { useMyProfile } from 'lib/hooks';

export const SurmonterMesCraintes = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'actionsEtudes',
    'imagineDans5ans'
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      <Form>
        <Box bg="blue.100" px={6} py={2} borderRadius={4} mb={8}>
          {myProfile?.mesQuestionnements?.length > 0 && (
            <>
              <Text fontWeight={'bold'}>Tu penses que...</Text>
              <ul>
                {myProfile?.mesQuestionnements.map((value) => (
                  <li key={value}>{getQuestionnementLabelByKey(value)}</li>
                ))}
              </ul>
            </>
          )}
          <Text>
            👉 C'est normal de se poser des questions et d'avoir quelques doutes
            ou craintes par rapport à son projet d'orientation. Pour t’aider à
            les dépasser et prendre confiance, réponds aux questions suivantes :
          </Text>
        </Box>
        <TextareaControl
          label="Écris une action que tu peux réaliser pour surmonter les défis que tu pourrais rencontrer quant aux études supérieures :"
          name="actionsEtudes"
          isRequired={true}
        />
        <TextareaControl
          label="Imagine ton quotidien dans 5 ans : dans quel environnement aimerais-tu travailler (bureau, extérieur, équipe, autonomie, etc.) ?"
          name="imagineDans5ans"
          isRequired={true}
          mt="10"
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};
