import { capacitesSocialesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const CapacitesSocialesADevelopper = ({ goNext }) => (
  <Capacites
    goNext={goNext}
    name="capacitesSocialesADevelopper"
    label="Quelles compétences aimerais-tu développer davantage ?"
    subLabel="Capacités sociales"
    options={capacitesSocialesOptions}
  />
);
