import { Box, Text } from '@chakra-ui/react';

export const Introduction = () => (
  <Box bg="blue.100" px={6} py={2} borderRadius={4} mb={8}>
    <Text fontWeight={'bold'}>Définition de “projet d’orientation”</Text>
    <Text>
      Un projet d'orientation est une démarche personnelle et réfléchie qui te
      permet de construire ton avenir en tenant compte de tes envies, de tes
      compétences et des opportunités qui s'offrent à toi. Il s'agit d'un plan
      global qui combine ton parcours scolaire (les études, formations ou
      spécialités que tu choisis) et ton futur professionnel (le métier ou le
      domaine d'activité dans lequel tu souhaites évoluer).
    </Text>
    <Text>
      Ce projet repose sur plusieurs étapes : mieux te connaître (tes intérêts,
      tes points forts, tes valeurs), découvrir les métiers et les secteurs qui
      t'attirent, et identifier les formations nécessaires pour y accéder. Le
      projet d'orientation est donc un outil pour te guider dans tes choix, te
      donner une direction, et te préparer à réussir dans la voie qui te
      correspond. C'est une réflexion que tu construis progressivement, avec
      l'aide de tes professeurs, de ta famille, et de professionnels.
    </Text>
  </Box>
);
