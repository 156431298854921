import {
  initFormikValuesFromSchema,
  validateSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';
import { useMyProfile } from 'lib/hooks';

export const TextAreaActivite = ({
  goNext,
  fieldName,
  label,
  textareaProps,
  isRequired,
}) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(fieldName).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      <Form>
        <TextareaControl
          label={label}
          name={fieldName}
          textareaProps={textareaProps}
          isRequired={isRequired}
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};
