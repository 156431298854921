import {
  ACTIVITE_STATUS_COMPLETED,
  ACTIVITE_STATUS_STARTED,
  ACTIVITE_CONTENT_TYPE_FORM,
} from '@inspire/data/static/activites';

import BilanModule from 'components/activites/BilanModule';

// This function transforms an array of eleves containing their own
// activitesBacPro statuses into an object of all activites containing
// the eleves' statuses by activite:
// {
//   'questionnaire-orientation': [
//     { firstName: 'Julie', lastName: 'Martin', status: 'completed' }
//     { firstName: 'Paul', lastName: 'Robert', status: 'completed' }
//   ],
//  ...

import { flatMap, keyBy, mapValues } from 'lodash';

// }
export const getActivitesByEleveObj = (modules, elevesActivitesBacPro) =>
  mapValues(
    keyBy(
      flatMap(modules, (m) => m.activites),
      'activiteId'
    ),
    (act) =>
      elevesActivitesBacPro
        ?.map((e) => ({
          id: e._id,
          firstName: e.profile?.firstName,
          lastName: e.profile?.lastName,
          status: e.activitesBacPro?.find(
            // @ts-ignore
            (ab) => ab.activiteId === act?.activiteId
          )?.status,
        }))
        .sort((a, b) => {
          const statusMap = {
            [ACTIVITE_STATUS_COMPLETED]: 1,
            [ACTIVITE_STATUS_STARTED]: 2,
            undefined: 3,
          };
          return statusMap[a.status] < statusMap[b.status]
            ? -1
            : statusMap[a.status] > statusMap[b.status]
            ? 1
            : a.lastName.toLowerCase() < b.lastName.toLowerCase()
            ? -1
            : a.lastName.toLowerCase() > b.lastName.toLowerCase()
            ? 1
            : 0;
        })
  );

export const makeBilanActivite = (_moduleId, requirements = null) => ({
  title: 'Bilan de module',
  activiteId: `${_moduleId}-bilan`,
  description:
    "Quelques questions rapides pour savoir si ce module t'a été utile !",
  profDescription:
    "Quelques questions rapides pour savoir si le module a été utile à l'élève.",
  contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
  duration: 1,
  component: BilanModule,
  hasOwnFooter: true,
  ...requirements,
});
