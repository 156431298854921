import { capacitesMethodologiquesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const CapacitesMethodologiquesADevelopper = ({ goNext }) => (
  <Capacites
    goNext={goNext}
    name="capacitesMethodologiquesADevelopper"
    label="Quelles compétences aimerais-tu développer davantage ?"
    subLabel="Capacités méthodologiques"
    options={capacitesMethodologiquesOptions}
  />
);
