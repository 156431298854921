export var questionnements = {
    etudes: {
        notesTropFaibles: "tes notes sont trop faibles.",
        etudesLongues: "les \xe9tudes longues, ce n’est pas pour toi.",
        etudesCheres: "les \xe9tudes co\xfbtent cher.",
        etudesNeGarantitPasUnTravail: "faire des \xe9tudes ne garantit pas un travail."
    },
    orientation: {
        tromperDeVoie: "tu pourrais te tromper de voie.",
        neSaisPasQuoiFairePlusTard: "tu ne sais pas encore ce que tu veux faire plus tard.",
        connaisPasLesOptions: "tu ne connais pas bien les options apr\xe8s le bac.",
        quitterVilleOuFamille: "quitter ta ville ou ta famille pour \xe9tudier serait trop difficile.",
        seSentirSeul: "tu as peur de te sentir seul pendant tes \xe9tudes."
    },
    travail: {
        trouverDuTravail: "trouver du travail dans ton domaine sera difficile.",
        sansExperience: "tu n’y arriveras pas sans exp\xe9rience.",
        pasAssezCompetences: "tu n’as pas assez de comp\xe9tences pour r\xe9ussir dans un m\xe9tier.",
        stagesNaidentPas: "les stages ne t’aideront pas vraiment \xe0 trouver un emploi stable.",
        hauteursAttentes: "tu risques de ne pas \xeatre \xe0 la hauteur des attentes des employeurs."
    }
};
export var getQuestionnementLabelByKey = function(key) {
    for(var index in questionnements){
        if (questionnements.hasOwnProperty(index)) {
            if (questionnements[index][key]) {
                return questionnements[index][key];
            }
        }
    }
};
