export var valeursOptions = {
    ecologie: {
        label: "\xc9cologie",
        description: "Je pr\xe9serve la plan\xe8te et je fais attention \xe0 mes choix pour l’environnement."
    },
    ambition: {
        label: "Ambition",
        description: "Je veux r\xe9ussir et atteindre de grands objectifs."
    },
    creativite: {
        label: "Cr\xe9ativit\xe9",
        description: "J’imagine des choses nouvelles et originales."
    },
    respect: {
        label: "Respect",
        description: "Je m’entends bien avec les autres et j’accepte leurs diff\xe9rences."
    },
    curiosite: {
        label: "Curiosit\xe9",
        description: "Je d\xe9couvre et j’explore plein de sujets."
    },
    justice: {
        label: "Justice",
        description: "Je d\xe9fends ce qui est juste, et lutte contre les in\xe9galit\xe9s."
    },
    securite: {
        label: "S\xe9curit\xe9",
        description: "Je cherche la stabilit\xe9 et la tranquillit\xe9."
    },
    solidarite: {
        label: "Solidarit\xe9",
        description: "J’aide les autres pour am\xe9liorer leur situation."
    },
    authenticite: {
        label: "Authenticit\xe9",
        description: "Je reste fid\xe8le \xe0 qui je suis."
    },
    integrite: {
        label: "Int\xe9grit\xe9",
        description: "Je suis honn\xeate et je respecte mes principes."
    },
    lucidite: {
        label: "Lucidit\xe9",
        description: "Je reconnais la r\xe9alit\xe9, m\xeame si elle est dure."
    },
    bienveillance: {
        label: "Bienveillance",
        description: "Je veux le bien des autres et je les encourage."
    },
    altruisme: {
        label: "Altruisme",
        description: "Je pense au bien-\xeatre des autres avant le mien."
    },
    adaptabilite: {
        label: "Adaptabilit\xe9",
        description: "Je m’ajuste aux impr\xe9vus avec calme."
    },
    perseverance: {
        label: "Pers\xe9v\xe9rance",
        description: "Je continue malgr\xe9 les difficult\xe9s."
    },
    discipline: {
        label: "Discipline",
        description: "Je suis organis\xe9 et je respecte les consignes."
    },
    audace: {
        label: "Audace",
        description: "J’ose prendre des risques pour avancer."
    },
    patience: {
        label: "Patience",
        description: "Je prends le temps pour bien faire et j’attends sans me d\xe9courager."
    }
};
