import { Box, Checkbox, SimpleGrid, Text } from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormControl } from 'formik-chakra-ui';
import { useMyProfile, useUser } from 'lib/hooks';
import Image from 'next/image';
import {
  valeursOptions,
  valeursOptionsInterface,
} from '@inspire/data/static/valeurs';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

const images = {
  brico: {
    img: 'brico.png',
    alt: 'Boite contenant des outils et des couverts.',
  },
  kaizen: {
    img: 'kaizen.png',
    alt: 'Personne assise sur une montagne en forme de racine. En arrière plan, on voit le soleil.',
  },
  lumiere: {
    img: 'lumiere.png',
    alt: 'Un être mystérieux encapuchonné tend une lanterne lumineuse à une petite silhouette agenouillée dans une forêt sombre et brumeuse.',
  },
  marcherseul: {
    img: 'marcherseul.png',
    alt: "Un personnage marche sur un pont brisé au-dessus d'une rivière, guidé par une étoile lumineuse entourée d'un symbole mystique dans un ciel coloré.",
  },
  montagne: {
    img: 'montagne.png',
    alt: "Un groupe de personnes se tient la main en marchant sur une plaine enneigée sous un ciel étoilé, avec des planètes lumineuses au-dessus d'eux.",
  },
  plante: {
    img: 'plante.png',
    alt: "Une plante est tenue dans le creux d'une main et un peu de terre en tombe.",
  },
};

export const MesValeurs = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'imageValeur',
    'motsclesValeur'
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      imageValeur: myProfile?.imageValeur,
      motsclesValeur: myProfile?.motsclesValeur,
    },
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {({ setFieldValue, values }) => (
        <Form>
          <SummaryFrame mb="10" label="Définition">
            <Text>
              Une <b>valeur</b> est une idée importante pour une personne ou
              pour un groupe de personnes. C’est ce qui guide nos choix, nos
              comportements, et nos jugements dans la vie de tous les jours.
            </Text>
            <Text>
              <b>Quelques points pour mieux comprendre :</b>
            </Text>
            <ol>
              <li>
                <b>Valeurs personnelles :</b> Ce sont celles qui comptent pour
                toi en tant qu’individu. Par exemple, tu peux valoriser la
                liberté, le respect, ou la famille.
              </li>
              <li>
                <b>Valeurs d’un groupe ou d’une société :</b> Ce sont des
                principes partagés par beaucoup de gens. Par exemple, dans une
                classe, le respect des autres ou la coopération peut être une
                valeur commune.
              </li>
              <li>
                <b>Valeurs professionnelles :</b> Dans ton futur métier, il y a
                aussi des valeurs importantes, comme la rigueur, l’esprit
                d’équipe ou l’éthique.
              </li>
            </ol>
          </SummaryFrame>

          <FormControl
            name={'imageValeur'}
            label={'Choisis une image parmi les 6 qui représente tes valeurs'}
            labelProps={{ textAlign: 'center', marginBottom: 10 }}
            isRequired
          >
            <SimpleGrid columns={[1, null, 3]} spacing={10}>
              {Object.entries(images).map(([key, value]) => (
                <Box key={key}>
                  <Image
                    src={`/img/activites/definir-mon-projet-orientation/${value.img}`}
                    width="300"
                    height="400"
                    alt={value.alt}
                    onClick={() => setFieldValue('imageValeur', key)}
                    style={{
                      filter:
                        !values.imageValeur || values.imageValeur === key
                          ? 'none'
                          : 'grayscale(100%)',
                      cursor: 'pointer',
                      borderRadius: 10,
                    }}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </FormControl>

          <FormControl
            name={'motsclesValeur'}
            label={
              <Text textAlign="center" fontWeight="bold" mt="10" mb="10">
                Choisis 3 valeurs qui correspondent selon toi à cette image et
                qui te correspondent aussi :{' '}
                <span style={{ color: 'red' }}>*</span>
              </Text>
            }
            labelProps={{
              textAlign: 'center',
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <FieldArray
              name={'motsclesValeur'}
              render={(arrayHelpers) =>
                Object.entries(valeursOptions as valeursOptionsInterface).map(
                  ([key, value]) => (
                    <Box key={key}>
                      <Checkbox
                        value={key}
                        mb={4}
                        isChecked={values?.motsclesValeur?.includes(key)}
                        disabled={
                          values?.motsclesValeur?.length === 3 &&
                          !values?.motsclesValeur?.includes(key)
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(key);
                            return;
                          }

                          const index = values?.motsclesValeur?.indexOf(key);
                          arrayHelpers.remove(index);
                        }}
                      >
                        <b>{value.label}</b> : {value.description}
                      </Checkbox>
                    </Box>
                  )
                )
              }
            />
          </FormControl>

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={
              isProf ||
              (!!values.imageValeur && values?.motsclesValeur?.length === 3)
            }
          />
        </Form>
      )}
    </Formik>
  );
};
