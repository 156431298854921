import { capacitesPersonnellesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const CapacitesPersonnelles = ({ goNext }) => (
  <Capacites
    goNext={goNext}
    name="capacitesPersonnellesStage"
    label="Quelles compétences transversales penses-tu maîtriser grâce à ce stage ?"
    subLabel="Capacités personnelles"
    options={capacitesPersonnellesOptions}
  />
);
