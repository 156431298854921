import { capacitesPersonnellesOptions } from '@inspire/data/static/capacites';
import { Capacites } from './Capacites';

export const capacitesPersonnellesADevelopper = ({ goNext }) => (
  <Capacites
    goNext={goNext}
    name="capacitesPersonnellesADevelopper"
    label="Quelles compétences aimerais-tu développer davantage ?"
    subLabel="Capacités personnelles"
    options={capacitesPersonnellesOptions}
  />
);
