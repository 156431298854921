import { Box, Checkbox, Text } from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormControl } from 'formik-chakra-ui';
import { useMyProfile } from 'lib/hooks';
import { capacitesOptionsInterface } from '@inspire/data/static/capacites';

export const Capacites = ({ name, label, subLabel, options, goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(name);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      [name]: myProfile?.[name],
    },
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormControl name={name} label={label} mt="10">
            <Text fontWeight={'bold'} ml="2">
              {subLabel}
            </Text>
            <FieldArray
              name={name}
              render={(arrayHelpers) =>
                Object.entries(options as capacitesOptionsInterface).map(
                  ([key, value]) => (
                    <Box key={key}>
                      <Checkbox
                        value={key}
                        mb={4}
                        isChecked={values?.[name]?.includes(key)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(key);
                            return;
                          }

                          const index = values?.[name]?.indexOf(key);
                          arrayHelpers.remove(index);
                        }}
                      >
                        <b>{value.label}</b> : {value.description}
                      </Checkbox>
                    </Box>
                  )
                )
              }
            />
          </FormControl>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={true}
          />
        </Form>
      )}
    </Formik>
  );
};
